import React from 'react';

const Hero = () => {
    return (
        <section className="hero">
            <div className="hero-content">
                <h1>欢迎来到基督徒单身社区</h1>
                <p>与其他基督徒单身人士建立有意义的联系，并通过课程和活动进行探索，以加深您的信仰</p>
                <a href="#" className="btn-primary">立即加入</a>
            </div>
        </section>
    );
}

export default Hero;
