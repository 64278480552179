import React from 'react';
import './AboutPage.css'; // Import CSS file for styling

const AboutPage = () => {
  return (
   <div className="about-page">
      <h1 className="about-title">About Us</h1>
      <p className="about-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in turpis eu ligula viverra ullamcorper. Aliquam erat volutpat. Donec suscipit tortor id ullamcorper gravida. Donec at semper est, non sollicitudin justo.</p>
      
  </div>

  );
 
};

export default AboutPage;
