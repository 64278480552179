import "../stylesheets/EditorDemo.css";
import React, { useState, useEffect } from "react";
import { serializer } from "../component/SlateEditor/utils/serializer";
import SlateEditor from "../component/SlateEditor/Editor.jsx";
import "katex/dist/katex.min.css";
import PostService from "../services/posts.service";
import { useLocation, useNavigate } from "react-router-dom";

function EditorDemo() {
  const location = useLocation();
  let navigate = useNavigate();
  let refId = null;

  if (location.state) {
    refId = location.state.refid;
  }

  localStorage.setItem(
    "currValue",
    JSON.stringify([
      {
        type: "paragaph",
        children: [{ text: "" }],
      },
    ])
  );

  const handleClick = (e) => {
    e.preventDefault();
    console.log("The link was clicked.");
    const currValue = localStorage.getItem("currValue");
    console.log(currValue);
    if (currValue) {
      PostService.savePost({
        title: "title 1",
        text: currValue,
        refid: refId,
      }).then(
        (response) => {
          //navigate("/profile");
          //window.location.reload();
          navigate("/post/"+response.data.postId)
          console.log(response.data.postId)
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          //setLoading(false);
          //setMessage(resMessage);
        }
      );
    } else {
      //setLoading(false);
    }
  };

  return (
    <div className="EditorDemo">
      <SlateEditor />
      {/* <InlineMath math="\int_0^\infty x^2 dx"/> */}
      {/* <BlockMath math="\int_0^\infty x^2 dx"/> */}
      <button className="btn btn-primary" onClick={handleClick}>
        <span>save</span>
      </button>
    </div>
  );
}

export default EditorDemo;
