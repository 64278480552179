import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './YouTubePage.css';

const YouTubePage = () => {
  const [video, setVideo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch one video from YouTube Data API
        const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
          params: {
            part: 'snippet',
            maxResults: 10, // Fetch only one video
            q: 'music', // Search query
            key: 'AIzaSyAyLY-_waSS1hcF2uVqajQQqltcnMp6gAw' // Your YouTube Data API key
          }
        });

        // Update state with fetched video
        setVideo(response.data.items[0]);
      } catch (error) {
        console.error('Error fetching video:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1>YouTube Video</h1>
      {video && (
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${video.id.videoId}`}
            title={video.snippet.title}
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <h2>{video.snippet.title}</h2>
          <p>{video.snippet.description}</p>
        </div>
      )}
    </div>
  );
};

export default YouTubePage;
