import { observable, action, makeObservable } from 'mobx';
import axios from 'axios';
import ProfileService from "../../services/profile.service";

const baseURL = process.env.REACT_APP_API_BASE_URL_RES;

const API_RES_URL = baseURL + "/api/res/" ;
const API_PUB_URL = baseURL + "/api/pub/"; 

class UserProfileDataStore {
    constructor() {        
        makeObservable(this, {
            data: observable,
            images: observable,
            loading: observable,
            error: observable,
            fetchProfileData: action,
            postProfileData: action,
            putProfileData : action,
            postData2: action,
            postProfileImageData: action,
           
        });
    }
    
    data = {'a':'b'};
    loading = false;
    error = null; 
    
    images =[];

    addurltoimage = (item) => {
        return baseURL + '/getimage?imgid=' + item; // Example transformation: Convert item to uppercase
      
    };
    
    fetchProfileData = async (id) => {
        this.loading = true;
        try {
            this.data = await ProfileService.fetchProfileData(id); 
            const idlist = await ProfileService.fetchProfileImageData(this.data.userId); 
            this.images = idlist.map(this.addurltoimage);         
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

    fetchMyProfileData = async () => {
        this.loading = true;
        try {
            this.data = await ProfileService.fetchMyProfileData();   
            const idlist = await ProfileService.fetchProfileImageData(this.data.userId);  
            this.images = idlist.map(this.addurltoimage);
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

    postProfileImageData = async (postData) => {
        this.loading = true;
        try {
            const idlist = await ProfileService.postProfileImageData(postData);
            console.log('Post Successful:');
            this.images = idlist.map(this.addurltoimage);
            // Optionally, you can update the data after posting
            // this.data.push(response.data);
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };
    
    postProfileData = async (postData) => {
        this.loading = true;
        try {
            await ProfileService.postProfileData(postData);
            console.log('Post Successful:');
            // Optionally, you can update the data after posting
            // this.data.push(response.data);
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

    putProfileData = async (putData) => {
        this.loading = true;
        try {
            console.log('putData :' +JSON.stringify(putData));
            this.data = await ProfileService.putProfileData(putData);
            console.log('Put Successful:');
            // Optionally, you can update the data after posting
            // this.data.push(response.data);
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

    postData2 = async (postData) => {
        this.loading = true;
        try {
            //const response = await axios.post('http://localhost:8081/api/pub/posttest', postData);
            await ProfileService.postData2(postData);
            console.log('Post Successful:');
            // Optionally, you can update the data after posting
            // this.data.push(response.data);
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };
}

const userProfileDataStore = new UserProfileDataStore();
export default userProfileDataStore;