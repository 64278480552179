import axios from "axios";
import authHeader from "./auth-header";

//const API_PUB_URL = 'http://localhost:8090/api/pub/';
//const API_RES_URL = 'http://localhost:8090/api/res/';

//const API_PUB_URL = 'http://34.130.190.39/res/api/pub/';
//const API_RES_URL = 'http://34.130.190.39/res/api/res/';

const baseURL = process.env.REACT_APP_API_BASE_URL_RES;

const API_RES_URL = baseURL + "/api/res/" ;
const API_PUB_URL = baseURL + "/api/pub/"; 

const fetchPostVwData=async ()=>{
  return await axios.get(API_PUB_URL + "allpostvws");
}

const getPostList = () => {
  return axios.get(API_PUB_URL + "allpost");
};

const getPostPageList = (pageNo, pageSize, sortBy) => {
  return axios.get(API_PUB_URL + "postbypage"+"?pageNo="+pageNo +"&pageSize="+pageSize+"&sortBy="+sortBy);
};
const getPost = (id) => {
  return axios.get(API_PUB_URL +"post/" +id);
};

const savePost = (data) => {
  return axios.post(API_RES_URL + "post",
  {
    title : data.title,
    text : data.text,
    refId : data.refid  
  } ,    
  { headers: authHeader() });
};

const updatePost = (data) => {
  return axios.put(API_RES_URL + "post/"+data.id,
  {
    title : data.title,
    text : data.text,
    jdoc : null
  } ,    
  { headers: authHeader() });
};

const PostService = {
  getPostList,
  fetchPostVwData,
  savePost,  
  getPost,
  updatePost,
  getPostPageList,
};

export default PostService;
