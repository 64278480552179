import React, { createContext, useState } from 'react';
import { Navigate } from 'react-router-dom';

const PrivsateRoute = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Check if user is already logged in based on localStorage or other mechanism
    return !!localStorage.getItem('user');
  });

  return isLoggedIn ? children : <Navigate to="/login" />;
};

export default PrivsateRoute;
