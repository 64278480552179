import { observable, action, makeObservable } from 'mobx';
import axios from 'axios';
import ProfileService from "../../services/profile.service";


class SearchDiscoveryStore {

    constructor() {        
        makeObservable(this, {
            data: observable,
            loading: observable,
            error: observable,
            fetchProfileList: action,
           
        });
    }


    data = [];
    loading = false;
    error = null;   

    fetchProfileList = async (id) => {
        this.loading = true;
        try {
            this.data = await ProfileService.fetchProfileList(id);           
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

}

const searchDiscoveryStore = new SearchDiscoveryStore();
export default searchDiscoveryStore;
