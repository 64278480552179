import { Text } from 'slate';
import { getBlock, getMarked } from './SlateUtilityFunctionsnew.js'
import ReactDOMServer from 'react-dom/server';
import escapeHtml from "escape-html";

const { renderToStaticMarkup, renderToString } = ReactDOMServer


export const serialize = node =>{
    if(Text.isText(node)){
        let string = getMarked(node,node.text);
        
        string = ReactDOMServer.renderToString(string);
               
        return string
    }
    const children = node.children.map((n) => serialize(n)).join('')
     
    let block = getBlock({children,element:node})
    block = renderToString(block)      

    console.log("1111111@@@@@@@@@@ " + block);
    
    return block;
}



export const serializer = editorValue =>{
    if(editorValue.length > 0){
        return editorValue.map(n => serialize(n)).join('')
    }
}

export const deserializer = (body) => {
    console.log(body);
}