
import './App.css';
import { BrowserRouter  as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Layout from './pages/Layout';
import NavBar from './component/NavBar';
import Login from "./pages/login/Login";
import Register from "./pages/login/Register";
import Profile from "./pages/profiles/UserProfilePage";
import Profilepub from "./pages/profiles/Profile";
import Resetpwd from './pages/login/Resetpwd';
import Changepwd from './pages/login/Changepwd';
import ListDemo from './pages/ListDemo';
import EditorDemo from './pages/EditorDemo';
import EditorDemo2 from './pages/EditorDemo2';
import Post from './pages/Post';
import Editexist from './pages/Editexist';
import Search from './pages/search/SearchDiscoveryPage';
import YouTubePage from './pages/course/YouTubePage';
import PresentationsPage from './pages/course/PresentationsPage';
import About from "./pages/AboutPage";
import Contact from "./pages/ContactPage";
import Events from "./pages/events/EventsPage";
import PostBoard from "./pages/posts/PostBoard";
import PrivateRoute from './PrivateRoute';


function App() {

  
  return (
    <>   
   
     <NavBar></NavBar>
     
     <Routes>
        <Route path="/" element={<Layout />}/>
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path="/profile/:id" element={<Profilepub />} />
        <Route path="/resetpwd/:rtoken" element={<Resetpwd />} /> 
        <Route path="/changepwd" element={<Changepwd />} />    
        <Route path="/search" element={<Search />} />
         <Route path="/listdemo" element={<ListDemo />} />   
         <Route path="/editordemo" element={<EditorDemo />} />
          <Route path="/post/:id" element={<Post />} />  
          <Route path="/editexist" element={<Editexist />} /> 
          <Route path="/youtube" element={<YouTubePage />} /> 
          <Route path="/presentation" element={<PresentationsPage />} /> 
          <Route path="/about" element={<About />} /> 
          <Route path="/contact" element={<Contact />} /> 
          <Route path="/events" element={<Events />} /> 
         {/* <Route path="/messageboard" element={<ProtectedEle Component={PostBoard} />} /> */}
          <Route path="/messageboard" element={<PrivateRoute><PostBoard /></PrivateRoute>} /> 
    </Routes>  
    
    </>
  );
}

export default App;
