import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL_AUTH;
//const API_URL = "http://localhost:8085/api/auth/";
//const API_URL = "http://34.130.190.39/auth/api/auth/";

const API_URL = baseURL +"/api/auth/";

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (userName, password) => {
  return axios
    .post(API_URL + "login", {
      userName,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const resetPwd = async (rtoken) => {  
  const response = await axios.get(API_URL + "resetpwd?token=" + rtoken); 
  if (response.data.accessToken) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return null;
};


const recoverpwd = async (email) => {
  return await axios.get(API_URL + "recoverpwd?email=" + email);  
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  resetPwd,
  recoverpwd,
};

export default AuthService;
