import React, { useCallback, useMemo, useState, useEffect } from "react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import { Slate, Editable, withReact } from "slate-react";
import Toolbar from "../component/SlateEditor/Toolbar/Toolbar";
import {
  getMarked,
  getBlock,
} from "../component/SlateEditor/utils/SlateUtilityFunctions.js";
import withLinks from "../component/SlateEditor/plugins/withLinks.js";
import withTables from "../component/SlateEditor/plugins/withTable.js";
import withEmbeds from "../component/SlateEditor/plugins/withEmbeds.js";
import withEquation from "../component/SlateEditor/plugins/withEquation.js";
import "../component/SlateEditor/Editor.css";
import CodeToText from "../component/SlateEditor/Elements/CodeToText/CodeToText";
import { serializer } from "../component/SlateEditor/utils/serializer";
import PostService from "../services/posts.service";
import { useParams, useLocation, useNavigate } from "react-router-dom";

const Element = (props) => {
  return getBlock(props);
};
const Leaf = ({ attributes, children, leaf }) => {
  children = getMarked(leaf, children);
  return <span {...attributes}>{children}</span>;
};


const Editexist = () => {

  const location = useLocation();
  let navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const editor = useMemo(
    () =>
      withEquation(
        withHistory(
          withEmbeds(withTables(withLinks(withReact(createEditor()))))
        )
      ),
    []
  );


  const initialValue =  [
        {
          type: 'paragraph',
          children: [{ text: 'A line of text in a paragraph.' }],
        },
      ];


  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    PostService.getPost(location.state.id).then(
      (response) => {
        //navigate("/profile");
       
        setValue((JSON.parse(response.data.text)));
        //initValue = serializer(JSON.parse(response.data.text));        
        localStorage.setItem('currValue', response.data.text)      
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        //setLoading(false);
        //setMessage(resMessage);
      }
    );
  }, []);

  const handleEditorChange = (newValue) => {
    console.log("newValue**** " + JSON.stringify(newValue));
    const currValue = JSON.stringify(newValue)
    localStorage.setItem('currValue', currValue)
    setValue(newValue);
  };

  const renderElement = useCallback((props) => <Element {...props} />, []);

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  const [htmlAction, setHtmlAction] = useState({
    showInput: false,
    html: "",
    action: "",
    location: "",
  });
  const handleCodeToText = (partialState) => {
    setHtmlAction((prev) => ({
      ...prev,
      ...partialState,
    }));
  };

  const handleClick = (e) => {
    e.preventDefault();
    console.log("The link was clicked.------"  + location.state.id);
    const currValue = localStorage.getItem("currValue");
    console.log(currValue);
    if (currValue) {
      PostService.updatePost({title: "title 2", text : currValue, jdoc : null, id : location.state.id}).then(
        (response) => {
          navigate("/post/"+response.data.postId)
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          //setLoading(false);
          //setMessage(resMessage);
        }
      );
    } else {
      //setLoading(false);
    }
  };



  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    <div className="EditorDemo">

      <Slate editor={editor} value={value} onChange={handleEditorChange}>
        <Toolbar handleCodeToText={handleCodeToText} />
        <div
          className="editor-wrapper"
          style={{ border: "1px solid #f3f3f3", padding: "0 10px" }}
        >
          <Editable
            placeholder="Write something"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        </div>
        {htmlAction.showInput && (
          <CodeToText {...htmlAction} handleCodeToText={handleCodeToText} />
        )}
      </Slate>

      <button className="btn btn-primary" onClick={handleClick}>
        <span>save</span>
      </button>
    </div>
  );
};

export default Editexist;
