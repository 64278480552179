import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
//import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { observer } from 'mobx-react';
import userProfileDataStore from './UserProfileDataStore';
import UpdateForm from './UpdateForm';
import UpdateFormSpecial from './UpdateFormSpecial';
import './UserProfilepage.css';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const UserProfilePage = observer(() => {

    useEffect(() => {
        // Trigger the API call to fetch data when the component mounts
        userProfileDataStore.fetchMyProfileData();
    }, []); // Empty dependency array ensures it runs only once on mount
    
    const { data, images, loading, error } = userProfileDataStore;

          
    // State variables for modals
    const [showBioModal, setShowBioModal] = useState(false);
    const [showPersonalModal, setShowPersonalModal] = useState(false);
    const [showInterestsModal, setShowInterestsModal] = useState(false);
    const [showContactInfoModal, setShowContactInfoModal] = useState(false);
    const [showFaithExperienceModal, setShowFaithExperienceModal] = useState(false);
    const [showProfessionBackgroundModal, setShowProfessionBackgroundModal] = useState(false);
    const [showRequirementModal, setShowRequirementModal] = useState(false);
    const [showChurchModal, setShowChurchModal] = useState(false);
    const [showTestimonyModal, setShowTestimonyModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [selectedFiles, setSelectedFiles] = useState([]);

    // const [form1InputNames, setForm1InputNames] = useState([]);
    // const [form2InputNames, setForm2InputNames] = useState([]);
    // const [form3InputNames, setForm3InputNames] = useState([]);
    // const [form4InputNames, setForm4InputNames] = useState([]);
    // const [form5InputNames, setForm5InputNames] = useState([]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        // Limit the number of selected files to 3
        const selectedFiles = files.slice(0, 3);
        setSelectedFiles(selectedFiles);
        setErrorMsg('');
      };
    
      const handleSubmit = async () => {
        // Handle file upload logic (e.g., send files to server)

        const MAX_FILE_SIZE = 1048576;
        var filesize = 0;
        const formData = new FormData();
        selectedFiles.forEach((file) => {
            filesize = filesize + file.size;
            formData.append('files', file);
        });

        if (filesize >= MAX_FILE_SIZE) {
            console.error('File size exceeds the maximum allowed size');
            setErrorMsg('File size is too large');
            return;
        }

        await userProfileDataStore.postProfileImageData(formData);
        console.log('Selected files:', selectedFiles);
      };

    const handleSubmitForm = async (formData, id) => {
        // Submission logic for the form
        console.log('Form Data:', formData);
        console.log('Form id:', id);
        // Example: Posting data for the form
        await userProfileDataStore.putProfileData(formData);
        toggleModal(id);
        //userProfileDataStore.postData2(formData);
    };

   // const form1InputNames = ['firstName', 'lastName', 'bio']; // Input names for form 1
    const form1InputNames = [{name: 'userName', label: 'User Name', disabled: true, defaultValue: data.userName},
                             {name: 'bio', label: 'Bio', defaultValue: data.bio}];
    const form2InputNames = [{name:'interests', label:'Interests', defaultValue: data.interests}]; // Input names for form 2
    const form7InputNames = [{name:'requirement', label:'Requirement', defaultValue: data.requirement}]; 
    const form3InputNames = [{name:'email', label:'Email', disabled: true, defaultValue: data.email},
                             {name:'phone', label:'Phone', defaultValue: data.phone},
                             {name:'wechat', label:'Wechat', defaultValue: data.wechat}]; // Input names for form 1
    const form4InputNames = [{name:'faithExperience', label:'属灵经历', defaultValue: data.faithExperience}]; // Input names for form 2
    const form5InputNames = [{name:'profession_background', label:'Profession Background', defaultValue: data.profession_background}]; // Input names for form 2
    const form6InputNames = [{name: 'firstName', label: '名', defaultValue: data.firstName},
                             {name: 'lastName', label: '姓', defaultValue: data.lastName},
                             {name: 'gender', label: '性别', type: 'radio', options: [{value :'M', display : '男'}, {value :'F', display : '女'}], defaultValue: data.gender},
                             {name: 'dob', label: '出生年月日', type: 'date', defaultValue: data.dob},
                             {name: 'city', label: '生活城市',  defaultValue: data.city},
                             {name: 'county', label: '所在国家',  defaultValue: data.county}]; // Input names for form 2
    const form8InputNames = [{name:'isChristian', label:'',  type: 'radio', options: [{value :'true', display : '基督徒'}, {value :'false', display : '非基督徒'}], defaultValue: data.isChristian}];                         
    const form9InputNames = [{name:'testimony', label:'得救见证', defaultValue: data.testimony}];
    const form10InputNames = [{name:'church', label:'所属教会', defaultValue: data.church}];


         // Function to toggle modals  requirement
     const toggleModal = (modal) => {
        switch (modal) {
            case 'bio':
                setShowBioModal(!showBioModal);
                break;
            case 'personal':
                setShowPersonalModal(!showPersonalModal);
                break;    
            case 'interests':
                setShowInterestsModal(!showInterestsModal);
                break;
            case 'contactInfo':
                setShowContactInfoModal(!showContactInfoModal);
                break;
            case 'faithExperience':
                setShowFaithExperienceModal(!showFaithExperienceModal);
                break;
            case 'professionBackground':
                setShowProfessionBackgroundModal(!showProfessionBackgroundModal);
                break;
            case 'requirement':
                setShowRequirementModal(!showRequirementModal);
                break;
            case 'church':
                setShowChurchModal(!showChurchModal);
                break; 
            case 'testimony':
                setShowTestimonyModal(!showTestimonyModal);
                break;         
            default:
                break;
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
      <Container>
        <Row>
          {/* Profile Picture and Basic Info */}
          <Col md={4}>
            <Card className="mb-4">
              <Card.Img variant="top" src="https://via.placeholder.com/150" />
              <Card.Body>
                <Card.Title>{data.userName}</Card.Title>
                <Card.Text>
                自我介绍:{" "}
                  {data.bio ||
                    "xxxLorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sodales, nulla vel feugiat dictum, tellus elit scelerisque lectus."}
                  <br></br>
                  <br></br>
                  <Button variant="primary" onClick={() => toggleModal("bio")}>
                    修改/自我介绍
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Additional Details */}
          <Col md={8}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>个人信息</Card.Title>
                <Card.Text>
                  <div className="row">
                    <div className="column">
                      姓名: {data.lastName}, {data.firstName} <br></br>
                      性别: {data.gender == 'M'? '男' :'女'}
                      <br></br>
                      出生年月日: {data.dob}
                    </div>
                    <div className="column">
                      所在国家: {data.county}
                      <br></br>
                      生活城市: {data.city}
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                  <Button
                    variant="primary"
                    onClick={() => toggleModal("personal")}
                  >
                    修改/个人信息
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>联系方式</Card.Title>
                <Card.Text>
                  <div className="row">
                    <div className="column3">电邮：{data.email}</div>
                    <div className="column3">
                      电话：{data.phone || "xxx@xxx"}
                    </div>
                    <div className="column3">微信：{data.wechat}</div>
                  </div>
                  {/*{data.contactNumber || ' Email: user@example.com<br />' +
                               ' Phone: 123-456-7890 <br />' +
    'Address: 123 Main St, City, Country'}*/}
                  <br></br>
                  <br></br>
                  <Button
                    variant="primary"
                    onClick={() => toggleModal("contactInfo")}
                  >
                    修改/联系方式
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>对另一半的期待</Card.Title>
                <Card.Text>
                  写给未来另一半的话： {data.requirement || "List se what you expect..."}
                  <br></br>
                  <br></br>
                  <Button
                    variant="primary"
                    onClick={() => toggleModal("requirement")}
                  >
                    Edit/View Requirement
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>兴趣爱好</Card.Title>
                <Card.Text>
                  {data.interests || "List of user's interests..."}
                  <br></br>
                  <br></br>
                  <Button
                    variant="primary"
                    onClick={() => toggleModal("interests")}
                  >
                    Edit/View Interests
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>工作性质，专业背景</Card.Title>
                <Card.Text>
                  {data.profession_background ||
                    "User's professional background and experience..."}
                  <br></br> <br></br>
                  <Button
                    variant="primary"
                    onClick={() => toggleModal("professionBackground")}
                  >
                    Edit/View Profession Background
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>
           
            <Card className="mb-4">
            <Card.Body>
            <Card.Title>你是基督徒吗？</Card.Title>
                <Card.Text>

                <UpdateFormSpecial
              inputNames={form8InputNames}
              onSubmit={handleSubmitForm}
              id={"isChristian"}
            />

                </Card.Text>
            </Card.Body> 
            </Card>


          {data.isChristian && (

<>
<Card className="mb-4">
<Card.Body>
  <Card.Title>得救见证</Card.Title>
  <Card.Text>
    {data.testimony ||
      "请分享你是如何成为基督徒的"}
    <br></br>
    <br></br>{" "}
    <Button
      variant="primary"
      onClick={() => toggleModal("testimony")}
    >
      Edit/View Testimony
    </Button>
  </Card.Text>
</Card.Body>
</Card>



            <Card className="mb-4">
              <Card.Body>
                <Card.Title>最难忘的属灵经历</Card.Title>
                <Card.Text>
                  {data.faithExperience ||
                    "User's faith-related experience and beliefs..."}
                  <br></br>
                  <br></br>{" "}
                  <Button
                    variant="primary"
                    onClick={() => toggleModal("faithExperience")}
                  >
                    Edit/View Faith Experience
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>所属教会</Card.Title>
                <Card.Text>
                  {data.church ||
                    "User's faith-related experience and beliefs..."}
                  <br></br>
                  <br></br>{" "}
                  <Button
                    variant="primary"
                    onClick={() => toggleModal("church")}
                  >
                    Edit/View Church
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>
</>
             )}  
            {/* Add more sections for settings, preferences, etc. */}
         </Col>

          {/* Photo Gallery */}
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Photo Gallery</Card.Title>

              <Carousel useKeyboardArrows={true}>
                {images.map((URL, index) => (
                  <div className="slide">
                    <img alt="sample_file" src={URL} key={index} />
                  </div>
                ))}
              </Carousel>
              {errorMsg}
              <input type="file" multiple onChange={handleFileChange} accept="image/*" />
             <button onClick={handleSubmit}>Upload</button>
            </Card.Body>
          </Card>

          {/* Add other sections for interests, contact info, etc. */}
        </Row>

        {/* Modals for each section */}
        <Modal show={showBioModal} onHide={() => toggleModal("bio")}>
          <Modal.Header closeButton>
            <Modal.Title>Edit/View Bio</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Form or content for editing/viewing bio */}
            <UpdateForm
              inputNames={form1InputNames}
              onSubmit={handleSubmitForm}
              id={"bio"}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => toggleModal("bio")}>
              Close
            </Button>
            {/* Save button or other actions */}
          </Modal.Footer>
        </Modal>

        <Modal show={showPersonalModal} onHide={() => toggleModal("personal")}>
          <Modal.Header closeButton>
            <Modal.Title>Edit/View Bio</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Form or content for editing/viewing bio */}
            <UpdateForm
              inputNames={form6InputNames}
              onSubmit={handleSubmitForm}
              id={"personal"}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => toggleModal("personal")}>
              Close
            </Button>
            {/* Save button or other actions */}
          </Modal.Footer>
        </Modal>

        <Modal
          show={showRequirementModal}
          onHide={() => toggleModal("requirement")}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit/View Requirement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Form or content for editing/viewing interests */}
            <UpdateForm
              inputNames={form7InputNames}
              onSubmit={handleSubmitForm}
              id={"requirement"}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => toggleModal("requirement")}
            >
              Close
            </Button>
            {/* Save button or other actions */}
          </Modal.Footer>
        </Modal>

        <Modal
          show={showInterestsModal}
          onHide={() => toggleModal("interests")}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit/View Interests</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Form or content for editing/viewing interests */}
            <UpdateForm
              inputNames={form2InputNames}
              onSubmit={handleSubmitForm}
              id={"interests"}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => toggleModal("interests")}
            >
              Close
            </Button>
            {/* Save button or other actions */}
          </Modal.Footer>
        </Modal>

        {/* Repeat the above Modal pattern for other sections */}

        <Modal
          show={showContactInfoModal}
          onHide={() => toggleModal("contactInfo")}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit/View Interests</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Form or content for editing/viewing interests */}
            <UpdateForm
              inputNames={form3InputNames}
              onSubmit={handleSubmitForm}
              id={"contactInfo"}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => toggleModal("contactInfo")}
            >
              Close
            </Button>
            {/* Save button or other actions */}
          </Modal.Footer>
        </Modal>

        <Modal
          show={showFaithExperienceModal}
          onHide={() => toggleModal("faithExperience")}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit/View Interests</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Form or content for editing/viewing interests */}
            <UpdateForm
              inputNames={form4InputNames}
              onSubmit={handleSubmitForm}
              id={"faithExperience"}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => toggleModal("faithExperience")}
            >
              Close
            </Button>
            {/* Save button or other actions */}
          </Modal.Footer>
        </Modal>

        <Modal
          show={showProfessionBackgroundModal}
          onHide={() => toggleModal("professionBackground")}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit/View Professional Background</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Form or content for editing/viewing interests */}
            <UpdateForm
              inputNames={form5InputNames}
              onSubmit={handleSubmitForm}
              id={"professionBackground"}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => toggleModal("professionBackground")}
            >
              Close
            </Button>
            {/* Save button or other actions */}
          </Modal.Footer>
        </Modal>

        <Modal
          show={showChurchModal}
          onHide={() => toggleModal("church")}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit/View Church</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Form or content for editing/viewing interests */}
            <UpdateForm
              inputNames={form10InputNames}
              onSubmit={handleSubmitForm}
              id={"church"}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => toggleModal("church")}
            >
              Close
            </Button>
            {/* Save button or other actions */}
          </Modal.Footer>
        </Modal>

        <Modal
          show={showTestimonyModal}
          onHide={() => toggleModal("testimony")}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit/View Professional Background</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Form or content for editing/viewing interests */}
            <UpdateForm
              inputNames={form9InputNames}
              onSubmit={handleSubmitForm}
              id={"testimony"}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => toggleModal("testimony")}
            >
              Close
            </Button>
            {/* Save button or other actions */}
          </Modal.Footer>
        </Modal>
      </Container>
    );
});

export default UserProfilePage;
