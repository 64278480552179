import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Modal from 'react-bootstrap/Modal';
import { isEmail } from "validator";

import AuthService from "../../services/auth.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 3 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 3 and 40 characters.
      </div>
    );
  }
};

const Register = () => {
  const form = useRef();
  const checkBtn = useRef();

  const [showModal, setShowModal] = useState(false);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");

  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.register(username, email, password).then(
        (response) => {
          setMessage(response.data.message);
          setSuccessful(true);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label htmlFor="username">用户名</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required, vusername]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">电邮</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>

             {/* <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
          </div>*/}
            <br></br>

            <div className="form-group">
             <span><Input type="checkbox"
                label="Checkbox"
                checked={checkboxChecked}
                onChange={handleCheckboxChange}
                required
              />
            <a href="#" onClick={() => setShowModal(true)}>
                 请阅读接受协议和免责声明
            </a>
            </span> 
            </div>
            <br></br>

              <div className="form-group">
                <></>
                <button className="btn btn-primary btn-block">注册</button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>协议和免责声明</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      三股绳单身网免责声明
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      本协议适用于
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      三股绳单身网
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      网下所有的服务、资料及信息，而且也适用于我们和您之间通过电话或微信等任何方式进行的任何沟通。
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>&nbsp;</p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      在使用三股绳单身网（以下简称“本网站”）的服务之前，请您务必仔细阅读并理解本免责声明。一旦您使用本网站，即表示您已阅读并同意接受本免责声明的所有条款。
    </span>
  </p>
  <ol>
    <li>
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(17,17,17)",
            fontFamily: "Roboto",
            fontSize: "12pt"
          }}
        >
          本网站的各项网络服务的所有权和运营权归三股绳单身网所有。本网站的服务为线上服务，包括本网站上显示的全部功能及服务。
        </span>
      </p>
    </li>
    <li>
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(17,17,17)",
            fontFamily: "Roboto",
            fontSize: "12pt"
          }}
        >
          本网站所发布的信息，归平台管理。一旦您注册成功，符合条件的用户享有可以浏览您的资料及发站内信的权利。在此基础上，需要浏览更多以及私信权利的，需要相应开通会员进行付费。
        </span>
      </p>
    </li>
    <li>
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(17,17,17)",
            fontFamily: "Roboto",
            fontSize: "12pt"
          }}
        >
          本网站实行用户实名制，未实名制的本网站有权关闭您的账号。未经过本网站客服信仰审核的，由个人原因自己退出的，平台不予退费。
        </span>
      </p>
    </li>
    <li>
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(17,17,17)",
            fontFamily: "Roboto",
            fontSize: "12pt"
          }}
        >
          您必须年满18周岁并才能注册成为三股绳单身网的会员或使用本网站。注册成为本网站之会员或使用本网站，表示您陈述并保证您已具备独立签定本协议，并遵守本协议所有条款之民事权利能力及民事行为能力。
        </span>
      </p>
    </li>
    <li>
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(17,17,17)",
            fontFamily: "Roboto",
            fontSize: "12pt"
          }}
        >
          本网站禁止安息日会、天主教、耶和华见证人会、极端灵恩派等异端注册，若在本网站客服审核信仰中查出，或者有会员举报是以上情况，将关闭其个人账户，会费不予退费。
        </span>
      </p>
    </li>
    <li>
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(17,17,17)",
            fontFamily: "Roboto",
            fontSize: "12pt"
          }}
        >
          本网站郑重声明不对注册会员身份的真实性负责，亦不对会员提交信息的真实性及其发表言论的正确性负责，用户应明确以上风险将完全由自己承担。
        </span>
      </p>
    </li>
    <li>
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(17,17,17)",
            fontFamily: "Roboto",
            fontSize: "12pt"
          }}
        >
          会员私下所发生的任何的行为，比如（借钱、骗钱骗色等）本网站不负任何的责任。
        </span>
      </p>
    </li>
    <li>
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(17,17,17)",
            fontFamily: "Roboto",
            fontSize: "12pt"
          }}
        >
          用户在使用本网站服务时必须符合中国和美国有关法规以及其他有关国家和地区的法律规定以及国际法的有关规定。用户不得利用本网站制作、发表、复制、传送、传播、储存等违法信息。
        </span>
      </p>
    </li>
    <li>
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(17,17,17)",
            fontFamily: "Roboto",
            fontSize: "12pt"
          }}
        >
          本网站有权在必要时修改服务条款，本网站服务条款一旦发生变动，将会在重要页面上提示修改内容。如果不同意所改动的内容，用户可以主动取消获得的本网站信息服务。如果用户继续享用本网站信息服务，则视为接受服务条款的变动。
        </span>
      </p>
    </li>
    <li>
      <p style={{ marginLeft: 0 }}>
        <span
          style={{
            color: "rgb(17,17,17)",
            fontFamily: "Roboto",
            fontSize: "12pt"
          }}
        >
          本服务条款的订立、执行和解释及争议的解决均应适用中国和美国法律并受中国和美国法院管辖。
        </span>
      </p>
    </li>
  </ol>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      特别声明：三股绳单身网已依法明确了上述条款、履行了格式条款制订方的义务，当您进行下一步注册，将被视为您已经完全注意并同意了本协议所有条款。
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      愿神赐福与您，早日找到人生伴侣！
    </span>
  </p>
  
  <p style={{ marginLeft: 0 }}>&nbsp;</p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      Three Ropes Singles Website Disclaimer
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      This agreement applies to all services, materials, and information of the
      Three Ropes Singles Website (hereinafter referred to as “this website”),
      and also applies to any communication between us and you through any means
      such as telephone or WeChat.
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      Before using the services of this website, please read and understand this
      disclaimer carefully. Once you use this website, it means that you have
      read and agreed to accept all terms of this disclaimer.
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      The ownership and operation rights of all network services of this website
      belong to the Three Ropes Singles Website. The services of this website
      are online services, including all functions and services displayed on
      this website.
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      The information published by this website is managed by the platform. Once
      you register successfully, users who meet the conditions have the right to
      browse your profile and send internal messages. On this basis, if you need
      to browse more and private message rights, you need to open a member to
      pay accordingly.
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      This website implements a real-name system for users, and this website has
      the right to close your account if it is not real-name. If you quit due to
      personal reasons without going through the faith review of this website’s
      customer service, the platform will not refund.
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      You must be at least 18 years old to register as a member of the Three
      Ropes Singles Website or use this website. Registering as a member of this
      website or using this website means that you declare and guarantee that
      you have the civil rights and civil conduct capabilities to independently
      sign this agreement and abide by all terms of this agreement.
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      This website prohibits the registration of heresies such as the
      Seventh-day Adventist Church, the Catholic Church, Jehovah’s Witnesses,
      and extreme Pentecostalism. If it is found out during the faith review of
      this website’s customer service, or if a member reports the above
      situation, it will close its personal account and the membership fee will
      not be refunded.
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      This website solemnly declares that it is not responsible for the
      authenticity of the registered member’s identity, nor is it responsible
      for the authenticity of the information submitted by the member and the
      correctness of his speech. Users should clearly understand that the above
      risks will be borne by themselves.
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      Any behavior that occurs privately by members, such as (borrowing money,
      deceiving money and deceiving color, etc.) this website is not
      responsible.
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      Users must comply with Chinese and American laws and regulations and other
      relevant national and regional laws and regulations and relevant
      provisions of international law when using the services of this website.
      Users must not use this website to produce, publish, copy, transmit,
      disseminate, store, etc. illegal information.
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      This website has the right to modify the service terms when necessary.
      Once the service terms of this website change, it will prompt the modified
      content on important pages. If you do not agree with the changed content,
      you can actively cancel the information service of this website. If the
      user continues to enjoy the information service of this website, it is
      deemed to accept the change of the service terms.
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      The establishment, execution, interpretation, and dispute resolution of
      this service agreement shall be governed by Chinese and American laws and
      subject to the jurisdiction of Chinese and American courts.
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      Special statement: The Three Ropes Singles Website has clearly stated the
      above terms in accordance with the law and fulfilled the obligations of
      the format clause formulator. When you proceed to the next step to
      register, it will be regarded as you have fully noticed and agreed to all
      terms of this agreement.
    </span>
  </p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      May God bless you and find your life partner soon!
    </span>
  </p>
  <hr />
  <p style={{ marginLeft: 0 }}>&nbsp;</p>
  <p style={{ marginLeft: 0 }}>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      Please note that this is a translation and may not fully capture all
      cultural and contextual nuances of the original text. If you have any
      questions or need further assistance, please let me know.{" "}
    </span>
    <span
      style={{
        backgroundColor: "rgb(255,255,255)",
        color: "rgb(13,13,13)",
        fontFamily: "Roboto",
        fontSize: "12pt"
      }}
    >
      God bless your marriage
    </span>
    <span
      style={{ color: "rgb(17,17,17)", fontFamily: "Roboto", fontSize: "12pt" }}
    >
      !
    </span>
  </p>
  <p>
    <br />
    &nbsp;
  </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Register;
