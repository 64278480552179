import "react-bootstrap-submenu/dist/index.css";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import React, { useState, useEffect } from "react";
import EventBus from "../../common/EventBus";
import { useParams, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";

const Resetpwd = () => {

  const { rtoken } = useParams();
  let navigate = useNavigate();

  AuthService.resetPwd(rtoken).then(
    () => {
      navigate("/changepwd");
      window.location.reload(); 
    },
    (error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        //setIsLoading(true);      
    }
  );

};

export default Resetpwd;