import axios from "axios";
import authHeader from "./auth-header";

// const API_PUB_URL = 'http://localhost:8081/api/pub/';
// const API_RES_URL = 'http://localhost:8081/api/res/';

const baseURL = process.env.REACT_APP_API_BASE_URL_RES;

const API_RES_URL = baseURL + "/api/res/" ;
const API_PUB_URL = baseURL + "/api/pub/"; 

const fetchProfileData = async (param) => {
    try {
        const response = await axios.get(API_PUB_URL + 'profile/' +param);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};


const fetchProfileImageData = async (param) => {
    try {
        const response = await axios.get(API_PUB_URL + 'imageids/' +param);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

const fetchMyProfileData = async () => {
    try {
        //console.log('@#@#@#@#@#@#@#@#-----'+ response.data);
        const response = await axios.get(API_RES_URL + 'profile/', { headers: authHeader() });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Handle 401 Unauthorized by redirecting to login page
            console.error('Unauthorized access. Redirecting to login page...');
            window.location.href = '/login'; // Redirect to login page
        } else {
            console.error('API Error:', error.message);
            throw new Error(error.message);
        }
    }
};

const postProfileData = async (postData) => {
    try {
        const response = await axios.post(API_RES_URL + 'profile', postData,    
        { headers: authHeader() });
        console.log('Post Successful:', response.data);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

const postProfileImageData = async (postData) => {
    try {
        const response = await axios.post(API_RES_URL + 'profileimage', postData,    
        { headers: authHeader() });
        console.log('Post image Successful:', response.data);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

const putProfileData = async (putData) => {
    try {
        const response = await axios.put(API_RES_URL + 'profilex', putData,    
        { headers: authHeader() });
        console.log('Put Successful:', response.data);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

const postData2 = async (postData) => {
    try {
        const response = await axios.post('http://localhost:8081/api/pub/posttest', postData);
        console.log('Post Successful:', response.data);
        return response.data;
        // Optionally, you can update the data after posting
        // this.data.push(response.data);
    } catch (error) {
        throw new Error(error.message);
    } 
};


const fetchProfileList = async (param) => {
    try {
        const response = await axios.get(API_PUB_URL + 'profiles/');
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

const ProfileService = {
    fetchProfileData,
    postProfileData,
    putProfileData, 
    fetchMyProfileData,  
    postData2, 
    fetchProfileList,
    postProfileImageData,
    fetchProfileImageData,
  };
  
  export default ProfileService;
