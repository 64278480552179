import React, { useState, useEffect } from "react";

const UpdateFormSpecial = ({ inputNames, onSubmit, id }) => {
  

  const [formData1, setFormData1] = useState({ });

  useEffect(() => {
    // Initialize initialFormData with default values from inputNames when component mounts
    const iniformData = inputNames.reduce((acc, input) => {
      console.log("@#@#@#@#@ input.defaultValue is " + input.defaultValue);
      console.log("@#@#@#@#@ input.name is " + input.name);
        acc[input.name] = input.defaultValue || '';
        return acc;
    }, {});
    console.log("@#@#@#@#@ formData is "+JSON.stringify(iniformData));
    console.log("@#@#@#@#@ formData[input.name] is "+iniformData['isChristian']);
    setFormData1(iniformData);
}, [inputNames]);

  // useEffect(() => {
  //     // Populate formData with default values from inputNames when component mounts
  //     setFormData(initialFormData);
  // }, [inputNames, initialFormData]);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log("%%%%%%%%%%%%%% value is "+ value);
  //   console.log("%%%%%%%%%%%%%% name is "+ name);
  //   // setFormData((prevFormData) => ({
  //   //   ...prevFormData,
  //   //   [name]: value,
  //   // }));


  //   setFormData1({
  //      });;

   
  //   console.log("%%%%%%%%%%%%%%% formData is "+JSON.stringify(formData1));
  //   //handleSubmit(e);
  //   //e.preventDefault();
  //   handleSubmit();
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData1(prevFormData1 => {
      prevFormData1[name] = value; // Update the existing value
     return { ...prevFormData1 }; // Return the updated object
    });

    console.log("%%%%%%%%%%%%%%% formData is "+JSON.stringify(formData1));
    //handleSubmit(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("%%%%%%%%%%%%%%%??????? formData is "+JSON.stringify(formData1));
    onSubmit(formData1, id);
  };

  return (
    <form onSubmit={handleSubmit}>
      {inputNames.map((input, index) => (
        <div key={index}>
          <label htmlFor={input.name}>
            {input.label} 
          </label>
          {input.type === 'radio' ? (
            input.options.map((option, i) => (
              <div key={i}>
                <input
                  type="radio"
                  id={option.value}
                  name={input.name}
                  value={option.value}
                  onChange={handleChange}
                  checked={String(formData1[input.name]) === option.value}
                />
                <label htmlFor={option.value}>{option.display}</label>
              </div>
            ))
          ) : input.type === 'date' ? ( // Render date picker for date type
          <input
            type="date"
            name={input.name}
            id={input.name}
            onChange={handleChange}
            value={formData1[input.name] || ''}
          />
        ) : (
            <input
              type="text"
              name={input.name}
              id={input.name}
              onChange={handleChange}
              value={formData1[input.name] || ''}
              disabled={input.disabled}
            />
          )}
        </div>
      ))}
      <button type="submit">Submit</button>
    </form>
  );
};

export default UpdateFormSpecial;
