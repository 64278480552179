import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Pagination from 'react-bootstrap/Pagination';
import searchDiscoveryStore from './SearchDiscoveryStore';
import { observer } from 'mobx-react';
import './SearchDiscoveryPage.css';

const SearchDiscoveryPage = observer(() => {
    // Sample array of search results

    // const [searchResults, setSearchResults] = useState({});
    // const searchResults = [
    //     { id: 1, firstName: 'Search Result 1', bio: 'Description of search result 1' },
    //     { id: 2, firstName: 'Search Result 2', bio: 'Description of search result 2' },
    //     { id: 3, firstName: 'Search Result 3', bio: 'Description of search result 3' },
    //     // Add more search results as needed
    // ];

    const searchResults =  [{"profileId":1,"userId":1,"firstName":"Yong","lastName":"Peng","email":"luke.py@gmail.com","bio":"zzzzzzzzzzzzzzzzzzzzz","interests":"ccccccccccczzzzzzzzzzzzz","contactNumber":null,"address":null,"faithExperience":null,"profession_background":"my ","profile_picture_url":null,"createdAt":"2024-02-28T02:57:01.000+0000","updatedAt":"2024-02-28T02:57:01.000+0000"},{"profileId":4,"userId":3,"firstName":"Riley","lastName":"Doe","email":"demo2@abc.com","bio":"My name is Riley See. I'm a recent elementary education graduate from Ball State University. I've been working at a camp for elementary children this summer, and I'm excited to find my first teaching position for the coming school year. I have several original lesson plans I created during my teaching internship that I look forward to implementing in my own classroom. I attended Brookwood Elementary myself and believe I would be a great fit for your second-grade opening. It would be a joy for me to teach students in the same place that sparked my love of learning.","interests":"bbbbbbbbbbbbbb","contactNumber":null,"address":null,"faithExperience":"My faith-related experience and beliefs...","profession_background":null,"profile_picture_url":null,"createdAt":null,"updatedAt":null},{"profileId":5,"userId":2,"firstName":"John1","lastName":"xie","email":"luke.py2200@gmail.com","bio":"My name is Sherilee Townsend, and I'm the marketing director at Wise Technology. We're developing some innovative marketing campaigns designed to engage new customers right in the streets of Bloomington. I've been recruiting local businesses that want to get involved in these activities. I love chatting with residents around the area and learning more about what they're looking for from their smart devices","interests":null,"contactNumber":null,"address":null,"faithExperience":null,"profession_background":null,"profile_picture_url":null,"createdAt":null,"updatedAt":null}]

    useEffect(() => {
        // Trigger the API call to fetch data when the component mounts
        searchDiscoveryStore.fetchProfileList();
    }, []); // Empty dependency array ensures it runs only once on mount
    
    const { data, loading, error } = searchDiscoveryStore;

    console.log('**********' + 
    JSON.stringify(data));

    //const [searchResults, setSearchResults] = useState({data});


    // Pagination variables
    const itemsPerPage = 2;
    const totalPages = Math.ceil(data.length / itemsPerPage);
    const [currentPage, setCurrentPage] = React.useState(1);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    // Handle pagination click
    const handlePaginationClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <Container>
            <Row className="mt-4">
                <Col>
                    <h2 className="text-center">Search and Discovery</h2>
                </Col>
            </Row>
            <Row className="mt-4 justify-content-center">
                <Col md={6}>
                    {/* Search Form */}
                    <Form className="mb-4">
                        <Form.Group controlId="searchQuery">
                            <Form.Control type="text" placeholder="Enter your search query" />
                        </Form.Group>
                        <Button variant="primary" type="submit" block>
                            Search
                        </Button>
                    </Form>
                </Col>
            </Row>
            {/* Display search results */}
            {currentItems.map((result) => (
                <Row key={result.profileId} className="justify-content-center">
                    <Col md={6}>
                        <Card className="search-result">
                            <Card.Body>
                                <Card.Title><Link to={`/profile/${result.profileId}`}>{result.userName}</Link></Card.Title>
                                <Card.Text>{result.bio}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            ))}
            {/* Pagination */}
            <Row className="justify-content-center">
                <Col>
                    <Pagination>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => handlePaginationClick(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </Col>
            </Row>
        </Container>
    );
});

export default SearchDiscoveryPage;
