import React from "react";
import Header from "../pages/home/Header";
import Hero from "../pages/home/Hero";
import FeaturedCoursesEvents from "../pages/home/FeaturedCoursesEvents";
import Testimonials from "../pages/home/Testimonials";
import Footer from "../pages/home/Footer";
import "./Home.css";

const Home = () => {
  return (
    <>
      <main>
        <Hero />
        <FeaturedCoursesEvents />
        <Testimonials />
      </main>
      <Footer />
    </>
  );
};

export default Home;
