import React from 'react';
import './ContactPage.css'; // Import CSS file for styling


const ContactPage = () => {
  return (
    <div className="contact-page">
      <h1 className="contact-title">Contact Us</h1>
      <div className="contact-info">
        <p><strong>Email:</strong> example@example.com</p>
        <p><strong>Phone:</strong> +1234567890</p>
        <p><strong>Address:</strong> 123 Main Street, City, Country</p>
      </div>
    </div>
  );
};

export default ContactPage;
