import React from 'react';
import './PresentationsPage.css'; // Import CSS file for component styles

const Presentation = ({ title, description, videoId }) => {
  return (
    <div className="presentation">
      <h3>{title}</h3>
      <p>{description}</p>
      <div className="video-container">
        <iframe
          title={title}
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

const PresentationsPage = () => {
  const presentations = [
    {
      title: 'Presentation 1',
      description: 'Description of presentation 1',
      videoId: 'z6vR_FV7s7c'
    },
    {
      title: 'Presentation 2',
      description: 'Description of presentation 2',
      videoId: 'FuiUrrMp4-I'
    },
    // Add more presentations as needed
  ];

  return (
    <div className="presentations-page">
      <h2 className="page-title">YouTube Presentations</h2>
      <div className="presentations-list">
        {presentations.map((presentation, index) => (
          <Presentation key={index} {...presentation} />
        ))}
      </div>
    </div>
  );
};

export default PresentationsPage;
