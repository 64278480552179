import React, { useState, useEffect } from "react";

const UpdateForm = ({ inputNames, onSubmit, id }) => {
  

  const [formData, setFormData] = useState({ });

  useEffect(() => {
    // Initialize initialFormData with default values from inputNames when component mounts
    const formData = inputNames.reduce((acc, input) => {
        acc[input.name] = input.defaultValue || '';
        return acc;
    }, {});
    setFormData(formData);
}, [inputNames]);

  // useEffect(() => {
  //     // Populate formData with default values from inputNames when component mounts
  //     setFormData(initialFormData);
  // }, [inputNames, initialFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      prevFormData[name] = value; // Update the existing value
      return { ...prevFormData }; // Return the updated object
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData, id);
  };

  return (
    <form onSubmit={handleSubmit}>
      {inputNames.map((input, index) => (
        <div key={index}>
          <label htmlFor={input.name}>
            {input.label} 
          </label>
          {input.type === 'radio' ? (
            input.options.map((option, i) => (
              <div key={i}>
                <input
                  type="radio"
                  id={option.value}
                  name={input.name}
                  value={option.value}
                  onChange={handleChange}
                  checked={formData[input.name] === option.value}
                />
                <label htmlFor={option.value}>{option.display}</label>
              </div>
            ))
          ) : input.type === 'date' ? ( // Render date picker for date type
          <input
            type="date"
            name={input.name}
            id={input.name}
            onChange={handleChange}
            value={formData[input.name] || ''}
          />
        ) : (
            <input
              type="text"
              name={input.name}
              id={input.name}
              onChange={handleChange}
              value={formData[input.name] || ''}
              disabled={input.disabled}
            />
          )}
        </div>
      ))}
      <button type="submit">Submit</button>
    </form>
  );
};

export default UpdateForm;
