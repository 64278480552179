import "../stylesheets/EditorDemo.css";
import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import "katex/dist/katex.min.css";
import PostService from "../services/posts.service";
import parse from "html-react-parser";
import { serializer } from "../component/SlateEditor/utils/serializer";
import EventBus from "../common/EventBus";
import { useParams } from "react-router-dom";
import EditorDemo from './EditorDemo';
import { useNavigate } from "react-router-dom";


const Post = () => {
  const { id } = useParams();
  const initialValue = [{ type: "paragaph", children: [{ text: "" }] }];
  const [value, setValue] = useState(serializer(initialValue));
  const navigate = useNavigate();
  //const location = useLocation();

  useEffect(() => {
    PostService.getPost(id).then(
      (response) => {
        //console.log("!!!!!!!!+" + serializer(JSON.parse(response.data.text)));
        //setValue(serializer(JSON.parse(response.data.text)));
        if(response.data){
           setValue(serializer(JSON.parse(response.data.text)));
        }
        //setM2(response.data.text);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  // const goEdit = () => {
  //   console.log(id);
  //   //EditorDemo();
  //   navigate('/editordemo',{props:{id : id}});
  // };

  return (
    <>
      <div className="EditorDemo">
        <h1>Editor Demo2 </h1>

        {parse(value)}
       
        <div className="evenDis">
          <div></div>
          <div><Link to="/editexist" state={{ id: id }}><button>Edit</button></Link></div>
          <div></div>
          {/*<div><button onClick={goEdit}>Reply</button></div>*/}
          <div><Link to="/editordemo" state={{ refid: id }}><button>Reply</button></Link></div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default Post;
