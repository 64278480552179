import { observable, action, makeObservable } from 'mobx';
import PostService from "../../services/posts.service";

class PostBoardStore {
    constructor() {        
        makeObservable(this, {
            data: observable,
         //   images: observable,
            loading: observable,
            error: observable,
            getPostPageList: action,
            postPostData: action,
             fetchPostVwData : action,
            // postData2: action,
            // postProfileImageData: action,
           
        });
    }
    
    data = [];
    loading = false;
    error = null; 
    
    //images =[];

    // addurltoimage = (item) => {
    //     return baseURL + '/getimage?imgid=' + item; // Example transformation: Convert item to uppercase
      
    // };
    
    getPostPageList = async (pageNo, pageSize, sortBy) => {
        this.loading = true;
        try {
            this.data = await PostService.getPostPageList(pageNo, pageSize, sortBy);                     
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

    fetchPostVwData = async () => {
        this.loading = true;
        try {
            this.data = await PostService.fetchPostVwData();   
            // const response = await PostService.fetchPostVwData();   
            // const data = await response.json();
            // this.data = data;
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

    // postProfileImageData = async (postData) => {
    //     this.loading = true;
    //     try {
    //         const idlist = await ProfileService.postProfileImageData(postData);
    //         console.log('Post Successful:');
    //         this.images = idlist.map(this.addurltoimage);
    //         // Optionally, you can update the data after posting
    //         // this.data.push(response.data);
    //     } catch (error) {
    //         this.error = error.message;
    //     } finally {
    //         this.loading = false;
    //     }
    // };
    
    postPostData = async (postData) => {
        this.loading = true;
        try {
            await PostService.savePost(postData);
            console.log('Post Successful:');
            // Optionally, you can update the data after posting
            // this.data.push(response.data);
        } catch (error) {
            this.error = error.message;
        } finally {
            this.loading = false;
        }
    };

    // putProfileData = async (putData) => {
    //     this.loading = true;
    //     try {
    //         console.log('putData :' +JSON.stringify(putData));
    //         this.data = await ProfileService.putProfileData(putData);
    //         console.log('Put Successful:');
    //         // Optionally, you can update the data after posting
    //         // this.data.push(response.data);
    //     } catch (error) {
    //         this.error = error.message;
    //     } finally {
    //         this.loading = false;
    //     }
    // };

    // postData2 = async (postData) => {
    //     this.loading = true;
    //     try {
    //         //const response = await axios.post('http://localhost:8081/api/pub/posttest', postData);
    //         await ProfileService.postData2(postData);
    //         console.log('Post Successful:');
    //         // Optionally, you can update the data after posting
    //         // this.data.push(response.data);
    //     } catch (error) {
    //         this.error = error.message;
    //     } finally {
    //         this.loading = false;
    //     }
    // };
}

const postBoardStore = new PostBoardStore();
export default postBoardStore;