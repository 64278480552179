import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
//import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import { observer } from 'mobx-react';
import userProfileDataStore from './UserProfileDataStore';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Profile = observer(() => {
    
    const { id } = useParams();
    useEffect(() => {
        // Trigger the API call to fetch data when the component mounts
        userProfileDataStore.fetchProfileData(id);
    }, []); // Empty dependency array ensures it runs only once on mount
    
    const { data, images, loading, error } = userProfileDataStore;

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }


    return (
        <Container>
        <Row>
            {/* Profile Picture and Basic Info */}
            <Col md={4}>
                <Card className="mb-4">
                    <Card.Img variant="top" src="https://via.placeholder.com/150" />
                    <Card.Body>
                        <Card.Title>{data.userName}</Card.Title>
                        <Card.Text>
                        自我介绍: {data.bio  || ''}
                           
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>

            {/* Additional Details */}
            <Col md={8}>
                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>{data.userName}</Card.Title>
                        <Card.Text>
                        <div className="row">
                        <div className="column3">性别: {data.gender == 'M'? '男' :'女'}</div>
                        <div className="column3">所在国家: {data.county}</div>
                        <div className="column3">生活城市: {data.city}</div>
                        </div>
                        </Card.Text>
                    </Card.Body>
                </Card>

                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>写给未来另一半的话</Card.Title>
                        <Card.Text>
                        {data.requirement || ''}
                            
                        </Card.Text>
                    </Card.Body>
                </Card>

                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>兴趣爱好</Card.Title>
                        <Card.Text>
                        {data.interests || ''}
                            
                        </Card.Text>
                    </Card.Body>
                </Card>

                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>工作性质，专业背景</Card.Title>
                        <Card.Text>
                        {data.profession_background || ''}
                          
                        </Card.Text>
                    </Card.Body>
                </Card>

                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>Faith-Related Experience</Card.Title>
                        <Card.Text>
                        {data.faithExperience ||   ''}
                        </Card.Text>
                    </Card.Body>
                </Card>

                

                {/* Add more sections for settings, preferences, etc. */}
            </Col>

            <Col md={12}>
                {/* Photo Gallery */}
                <Card className="mb-4">
                    <Card.Body>
                        <Card.Title>Photo Gallery</Card.Title>
                        <Carousel useKeyboardArrows={true}>
                {images.map((URL, index) => (
                  <div className="slide">
                    <img alt="sample_file" src={URL} key={index} />
                  </div>
                ))}
              </Carousel>
                    </Card.Body>
                </Card>

                {/* Add other sections for interests, contact info, etc. */}
            </Col>
        </Row>
        </Container>

    );

});

export default Profile;
