import { React, useState } from "react";

const Iframe = () => {
  //const {inline,math} = element;
  // console.log("********* " + children  + JSON.stringify(element));
  // console.log("********* attributes "   + JSON.stringify(attributes));
  // console.log("********* math "   + JSON.stringify(math));
  // console.log("********* element "   + JSON.stringify(element));

  return (
    <div className="counter">
      <h1>React Counter</h1>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/zWh3CShX_do"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default Iframe;
