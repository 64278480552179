import "react-bootstrap-submenu/dist/index.css";
import Button from "react-bootstrap/Button";
import Form from "react-validation/build/form";
import React, { useState, useRef, useEffect } from "react";
import EventBus from "../../common/EventBus";
import { useParams } from "react-router-dom";
import AuthService from "../../services/auth.service";
//import "../stylesheets/EditorDemo.css";
import "./Changepwd.css";
import Stack from "react-bootstrap/Stack";
import UserService from "../../services/user.service";
import { useNavigate } from 'react-router-dom';


const Changepwd = () => {
  let navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());

  const [disabled, setDisabled] = useState(true);

  const form = useRef();

  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          }else if (value.length<5){
            stateObj[name] = " Password is not long enough.";
          }else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          } else if(value.length >= 5){
            setDisabled(false);
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    UserService.getPwdUpdated(input.password).then(
      (response) => {
        //setContent(response.data);
        navigate("/profile");
        window.location.reload();
        console.log("!!!!!!!! pwd update  " + input.password);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        //setContent(_content);
      }
    );
  };

if(currentUser==null){
  <>
    not allowed 
  </>

}
  return (
    <>
      <div className="EditorDemo">
        <div className="cpwd">
          <Stack gap={2} className="col-md-5 mx-auto">
            <form onSubmit={handleSubmit} ref={form}>
              <input
                type="password"
                name="password"
                placeholder="Enter New Password"
                value={input.password}
                onChange={onInputChange}
                onBlur={validateInput}
              ></input>
              {error.password && <span className="err">{error.password}</span>}

              <input
                type="password"
                name="confirmPassword"
                placeholder="Enter Confirm Password"
                value={input.confirmPassword}
                onChange={onInputChange}
                onBlur={validateInput}
              ></input>
              {error.confirmPassword && (
                <span className="err">{error.confirmPassword}</span>
              )}
              {currentUser ? <button disabled ={disabled}>Submit</button> : <></>}
            </form>
          </Stack>
        </div>
      </div>
    </>
  );
};

export default Changepwd;
