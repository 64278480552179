import Stack from "react-bootstrap/Stack";
import ListGroup from "react-bootstrap/ListGroup";
import "../stylesheets/EditorDemo.css";
import parse from "html-react-parser";
import { serializer } from "../component/SlateEditor/utils/serializer";
import PostService from "../services/posts.service";
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Pagination from 'react-bootstrap/Pagination';


import { Outlet, Link } from "react-router-dom";

const ListDemo = () => {
  const [value, setValue] = useState([]);
  const [show, setShow] = useState(false);

  const [active, setActive] = useState(4);
  const [pageSize, setPageSize] = useState(5);
  const [sortBy, setSortBy] = useState("");
  const [items , setItems ] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const v1 = serializer([
    { type: "paragaph", children: [{ text: "this is a test " }] },
    {
      type: "equation",
      inline: false,
      math: "a=x+y",
      children: [{ text: "" }],
    },
  ]);

  function pagination(number) {

    setActive(number);
  }

  useEffect(() => {
    PostService.getPostPageList(active - 1, pageSize, sortBy).then(
      (response) => {
        setValue(response.data.postList);
        //setValue(serializer(JSON.parse(response.data.text)));
        //setM2(response.data.text);
        let items1 = []; 
        let totPage = response.data.pageTot/response.data.pageSize +1;
        for (let number = 1; number <= totPage; number++) {
          items1.push(
            <Pagination.Item key={number} active={number === active} onClick={() => pagination(number)}>
              {number}
            </Pagination.Item>,
          );
        }

        setItems(items1);

      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        // setContent(_content);

        if (error.response && error.response.status === 401) {
          //EventBus.dispatch("logout");
        }
      }
    );
  }, [active]);

  const paginationBasic = (
    <div>      
      <Pagination size="sm">{items}</Pagination>
    </div>
  );

  return (
    <>
    <Button variant="primary" onClick={handleShow}>
        Launch
    </Button>
      <div className="EditorDemo">
       {paginationBasic}
        <ListGroup>
          <Stack direction="vertical" gap={3}>
            {value.map((d) => (
              <ListGroup.Item key={d.postId}>
                <div
                  style={{
                    height: 150,
                    overflow: "hidden",
                  }}
                >
                  {parse(serializer(JSON.parse(d.text)))}
                </div>
                <div className="evenDis">
                   <div>1</div><div>2</div>
                   <div><Link to = {{pathname: '/post/'+d.postId}}><Button variant="Infor" size ="sm">Expand</Button></Link></div>
                   <div>4</div><div>5</div>
                </div>
              </ListGroup.Item>
            ))}           
          </Stack>
        </ListGroup>
      </div>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default ListDemo;
