import { useFocused, useSelected, useSlateStatic } from 'slate-react'
import { InlineMath, BlockMath } from 'react-katex';
import { React, useState } from 'react'


const Clickable = () => {
    //const {inline,math} = element; 
    // console.log("********* " + children  + JSON.stringify(element));
    // console.log("********* attributes "   + JSON.stringify(attributes));
    // console.log("********* math "   + JSON.stringify(math));
    // console.log("********* element "   + JSON.stringify(element));

    const [counter, setCounter] = useState(0);
    const increase = () => {
        setCounter(count => count + 1);
    };

    const decrease = () => {
        setCounter(count => count - 1);
      };
     
      //reset counter 
      const reset = () =>{
        setCounter(0)
      }

    return (
        <div className="counter">
      <h1>React Counter</h1>
      <span className="counter__output">{counter}</span>
      <div><input type="text" /></div>
      <div className="btn__container">
        <button className="control__btn" onClick={increase}>+</button>
        <button className="control__btn" onClick={decrease}>-</button>
        <button className="reset" onClick={reset}>Reset</button>
      </div>
    </div>
    )
}

export default Clickable