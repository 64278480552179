import axios from "axios";
import authHeader from "./auth-header";

//const API_URL = 'http://localhost:8090/api/res/';
//const API_PUB_URL = 'http://localhost:8090/api/pub/';

//const API_URL = 'http://lukepeng4de.info/res/api/res/';
//const API_PUB_URL = 'http://lukepeng4de.info/res/api/pub/';

//const API_URL = 'http://3.144.12.59/res/api/res/';
//const API_PUB_URL = 'http://3.144.12.59/res/api/pub/';

//const API_URL = 'http://34.130.190.39/res/api/res/';
//const API_PUB_URL = 'http://34.130.190.39/res/api/pub/';
//const API_URL = 'http://localhost:8090/api/res/';
//const API_PUB_URL = 'http://localhost:8090/api/pub/';

const baseURL = process.env.REACT_APP_API_BASE_URL_RES;

const API_URL = baseURL + "/api/res/" ;
const API_PUB_URL = baseURL + "/pub/"; 


const getPublicContent = () => {
  return axios.get(API_PUB_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

const getPwdUpdated = (pwd) => {
  return axios.get(API_URL + "restpwd/"+pwd, { headers: authHeader() });
};

const UserService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getPwdUpdated,
};

export default UserService;
