
import { Outlet, Link } from "react-router-dom";
import NavBar from '../component/NavBar';
import './LandingPage.css';

const Layout = () => {
  return (
    <div className="landing-page">
      <div className="background-image"></div>
      <div className="content">
        <section className="intro-section">
          <h1 className="intro-title"> 欢迎来到三股绳基督徒单身社区</h1>
          <p className="intro-description">与众基督徒同伴建立有意义的联系</p>
          <div className="cta-buttons">
            <Link to="/register" className="cta-button">加入</Link>
            <Link to="/login" className="cta-button">登录</Link>
          </div>
        </section>
        <br></br>
        <br></br>
        <div className="features">
          <div className="feature">
            <h2>连结</h2>
            <p>结识具有相同信仰和想法的基督徒，彼此连结，相互扶持，一起创建一个安全包容的社区环境</p>
          </div>
          <div className="feature">
            <h2>探寻</h2>
            <p>建立新的友谊，寻找人生伴侣，分享属灵经历，拓展发展空间，探寻见证基督信仰的魅力</p>
          </div>
          <div className="feature">
            <h2>成长</h2>
            <p>促进信仰的长进，见证生命的成熟，明白上帝对我们个人的呼召，共同成长，荣耀上帝</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Layout;
